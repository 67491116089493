<template>
  <div>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card >
        <v-card-title class="text-h5">
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  text @click="$emit('closeDelete')">Cancel</v-btn>
          <v-btn  text @click="$emit('deleteItemConfirm')">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['darkmode', 'dialogDelete'],
  name: "DialogDelete"
}
</script>

<style scoped>

</style>

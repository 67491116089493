<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t('title.maintenance_title') }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-col
          class="d-flex mt-5 mr-3"
          cols="12"
          sm="6"
        >
          <v-select
            v-model="type_id"
            :items="items"
            item-text="name"
            item-value="_id"
            :label="$t('input.chose_type')"
            @input="selectLimitBetting(type_id)"
          ></v-select>
        </v-col> -->
      <!-- <v-text-field
          v-model="params.search"
          append-icon="mdi-magnify"
          :label="$t('input.search')"
          single-line
          hide-details
          clearable
          @input="fetchGetAllConfigUser(params)"
          class="mr-5"
      /> -->
      <v-text-field v-model="params.search"
                          append-icon="mdi-magnify"
                          :label="$t('input.search')"
                          single-line
                          hide-details
                          clearable
                          @input="fetchGetAllBanner(params)"
                          class="mr-5" />

      <!-- <v-btn color="error"
             @click="dialogDelete = !dialogDelete"
             disabled>
        {{ $t('button.delete_all') }}
      </v-btn> -->

      <v-btn class="ml-5" color="primary" @click="onCreateDialog" v-if="checkUserPermission('CREATE')">{{ $t('button.create') }}</v-btn>

      <dialog-delete
          :dialog-delete="dialogDelete"
          :darkmode="darkmode"
          v-on:closeDelete="closeDelete"
          v-on:deleteItemConfirm="deleteItemConfirm"
      /> 
    </v-toolbar>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import DialogDelete from "../../../components/shared/notify/DialogDelete";

export default {
  components: {DialogDelete},
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission'],
  data() {
    return {
      dialogDelete: false,
      items:[
        { _id:1,
          name:"លុយដុល្លា - Usd"
        },
        { _id:2,
          name:"លុយរៀល - Khmer"
        },
        { _id:3,
          name:"លុយបាត - Baht"
        },
        ],
        type_id:1
    };
    
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },

    async deleteItemConfirm() {
      await this.deleteData({
        vm: this,
        data: {deleteItems: this.dataDelete}
      });
      this.fetchGetAllConfigUser(this.params)
      this.dialogDelete = false;
    },
    
      async selectLimitBetting(type_id) {
        this.params.currency_type = type_id
        this.fetchGetAllConfigUser(this.params)
    },

    async onCreateDialog() {
      this.fetchPopUp(true)
    },
    ...mapActions("$_maintenance", ["fetchGetAllBanner", "deleteData", "fetchPopUp"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
